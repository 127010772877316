body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.login-wrap {
	width: 420px;
	margin: 0% auto 0px;
	background: #f1f6fc;
	border-radius: 20px;
	padding: 20px;
	font-size: 14px;
}
.login-wrap input {
	font-size: 14px;
}
.login-wrap a {
	color: #8a8a8a;
	text-decoration: none;
}
.login-wrap a:hover {
	color: #545455;
}
.login-header {
	margin: 0px auto 14px;
	text-align: center;
	font-size: 20px;
	font-weight: 600;
}
