a,
a:hover {
  text-decoration: none;
}

.error-log-table tr td:last-child {
  width: 65% !important;
}

.audit-log-table tr td:last-child {
  width: 7% !important;
}


::placeholder {
  color: #232323 !important;
  opacity: 0.4 !important;
  font-size: 12px;
}

div#wrapperdashboard {
  width: 95%;
  /* background: #E9EEF7; */
  background: #ffffff;
  border-radius: 15px;
  margin: 0px auto;
  margin-top: 20px;
  display: flex;
  box-shadow: 0px 0px 30px #c8c8c8;

}

.content-area-dashboard h2 {
  font-size: 16px;
}

.content-area-dashboard {
  width: calc(100% - 242px);
  margin-left: 6px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  height: 92vh;
  overflow: auto;
  background: #d5dae1;
  padding: 0px 10px;
}

#sidebar-dashboard {
  width: 210px;
  background: #fff;
  border-radius: 10px;
  border-width: 0px 0px 0px 0px;
  margin: 7px;
  background-size: contain;
  height: 92vh;
  overflow: auto;
  box-shadow: 10px 0px 20px 0px #0000001c;
  position: static;
  left: 0px;
  opacity: 1;
  display: block;
}

#sidebar-dashboard ul.nav-link {
  margin: 0px;
  list-style: none;
}

a.active {
  background: #d8ceff;
  box-shadow: 1px 1px 14px 4px rgb(0 0 0 / 10%);
}

#sidebar-dashboard ul.nav-link li a {
  display: block;
  font-size: 13px;
  padding: 10px 10px;
  color: #333;
  margin: 0px 5px 3px 5px;
  border-radius: 4px;
}

#sidebar-dashboard ul.nav-link li ul li a {
  margin-bottom: 0px;
}

#sidebar-dashboard ul.nav-link li a svg:not(:root).svg-inline--fa,
#sidebar-dashboard ul.nav-link li a svg:not(:host).svg-inline--fa {
  overflow: visible;
  box-sizing: content-box;
  width: 30px;
  font-size: 19px;
}

#sidebar-dashboard ul.nav-link li ul {
  list-style: none;
  background-color: #fff;
  margin: 0px;
  padding: 10px 0px 0px 10px;
}

.top-box {
  width: 100%;
  background: #ccc;
  height: 50px;
  margin-top: 10px;
  border-radius: 10px;
}

.before-after {
  margin-top: -7px;
  padding: 0px 10px;
  color: #fff;
  font-size: 10px;
  font-weight: 700;
}

.graph-box {
  width: 100%;
  background: #fff;
  padding: 10px 10px;
  margin-top: 10px;
  /* height: 285px; */
}

.graph-box-2 {
  width: 100%;
  background: #fff;
  padding: 10px 10px;
  margin-top: 10px;
  height: 228px;
}


.fill-button {
  background: linear-gradient(291deg, rgb(80 65 225) 0%, rgb(123 95 211) 100%);
  color: #ffffff;
  padding: 10px 30px;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  border: solid 2px #6a58ff;
  margin-bottom: 5px;
}



.outline-button {
  background: #fff;
  color: #484848;
  padding: 10px 30px;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  border: solid 2px #6a58ff;
  margin-bottom: 5px;
}

.blue-bg {
  background-color: #5330B9;
}

.purple-bg {
  background-color: #8118BC;
}

.dark-blue-bg {
  background-color: #0C105A;
}

.top-box h2 {
  font-size: 18px;
  position: relative;
  width: 100%;
  padding: 0px 10px;
}

span.leftvalue {
  left: 10px;
}

.top-box h2 span {
  position: absolute;
  right: 10px;
  width: 60px;
  height: 35px;
  background: #0000006e;
  padding: 0px 0px;
  top: 7px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.outline-button:hover {
  background: linear-gradient(291deg, rgb(80 65 225) 0%, rgb(123 95 211) 100%);
  color: #ffffff;
  padding: 10px 30px;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  border: solid 2px #6a58ff;
  margin-bottom: 5px;
}

label.next-arrow-new,
label.prev-arrow-new {
  background: #f6e6ff !important;
  color: #000;
}

#tablelist .dropdown-toggle::after {
  display: none;
}

#tablelist button#dropdownMenuButton1 {
  background: transparent;
  color: #000;
  border: solid #ccc;
  padding: 2px 10px;
}

.btn-small {
  height: 35px;
  display: flex;
  align-items: center;
}

#tablelist .dropdown-menu a {
  text-decoration: none;
  padding: 5px 10px;
  border-bottom: solid 1px #ccc;
  display: block;
  width: 100%;
  font-size: 16px;
}

#sidebar-dashboard img {
  border-radius: 15px;
  width: 120px;
}

#sidebar-dashboard h2 {
  text-align: center;
}

button.edit {
  border: solid 2px #5330b9;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 11px;
  background-color: #fff;
  color: #333;
}

button.edit:hover {
  background-color: #5330b9;
  color: #fff;
}

.add-new {
  border: solid 2px #052752;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 11px;
  background-color: #052752;
  color: #ffffff;
  height: 30px;
}

.add-new:hover {
  background-color: #5330b9;
  color: #fff;
}

.delete {
  border: solid 2px #ff0c0c;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 11px;
  background-color: #ffffff;
  color: #ff0000;
}

.delete:hover {
  background-color: #fd3838;
  color: #fff;
  border: solid 2px #9e307d;
}

table tr td:last-child,
table tr th:last-child {
  width: 180px !important;
}

#wrapperdashboard table tr th {
  border-bottom: solid 1px #000;
  background: #224a7c !important;
  color: #fff;
  white-space: nowrap;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #9c27b0;
  border-color: #9c27b0;
}

.err-msg-used {
  background: #ff000024;
  padding: 10px 10px;
  color: #e84343;
  border-radius: 5px;
  border: solid 1px #ffbaba;
  margin-top: 10px;
}

.demo-products-cmt {
  position: relative;
  margin-bottom: 20px;
}

.demo-products-cmt label {
  position: absolute;
  width: 140px;
  background: #eadef7;
  height: 55px;
  border-radius: 5px 0px 0px 5px;
  display: flex;
  align-items: center;
  padding: 0px 11px;
}

.demo-products-cmt textarea {
  padding-left: 145px !important;
  height: 55px !important;
}

.modalscroll {
  max-height: 220px;
  overflow: auto;
  padding-right: 10px;
  margin-bottom: 10px;

}


/* -----------css for iphone portrait ------------------- */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {

  body {
    background-color: #ffffff;
  }

  .showing-data {
    flex-direction: column;
  }


}

/* -----------css for iPad mini portrait ------------------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {

  #sidebar-dashboard.custom-nav {
    position: fixed;
    width: 100% !important;
    height: 100vh;
    background: #fff;
    top: 0px;
    left: -1550px;
    z-index: 99;
    transition: all 0.3s ease-in;
    padding-bottom: 150px;
  }

  .content-area-dashboard .dropdown-menu-buttons {
    display: none;
  }

  .content-area-dashboard .dropdown-menu-buttons.show {
    display: block;
  }

  .pivoted span {
    float: right;
    width: 60%;
  }

  #example tr td:last-child {
    width: 100% !important;
  }

  #example .dropdown-menu li {
    width: 100%;
  }

  .responsiveTable tbody tr {
    border: 1px solid #000;
    padding: 0.25em;
  }

  .ymt .form-select {
    height: 30px !important;
  }

  #sidebar-dashboard.custom-nav.opensidebar {
    left: 0px;
    z-index: 9999999999;
  }

  .opensidebar ul li a {
    color: #000 !important;
    font-size: 17px;

  }

  a.closesidebar {
    position: absolute;
    top: -2px;
    right: 18px;
    font-size: 30px;
    color: #000;
    display: block !important;
  }

  .content-area-dashboard {
    width: 100%;
    margin-top: 40px;
  }

  #wrapperdashboard .navbar-expand-lg {
    position: fixed;
    width: 100%;
    background: #fff;
    height: 60px;
    left: 0px;
    top: 0px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
  }

  #wrapperdashboard .navbar-toggler {
    position: fixed;
    left: 15px;
    top: 14px;
    right: unset;
    display: block;
  }
}

/* -----------css for below 767 devices ------------------- */

@media screen and (max-width: 767px) {



  .graph-box-2 {
    height: 258px;
  }

  .graph-box {
    width: 100%;
    background: #fff;
    padding: 10px 10px;
    margin-top: 10px;
    height: auto;
  }

  #wrapperdashboard table {
    white-space: nowrap
  }

  #wrapperdashboard ul.cust-pagination {
    width: 100%;
    align-items: flex-start;
    margin: 0px 0px 0px 0px !important;
    padding: 0px;
  }

  #wrapperdashboard .btn-small {
    height: 34px;
  }

  #wrapperdashboard .fill-button {
    margin-bottom: 0px;
  }

  #wrapperdashboard .fill-button .me-2 {
    margin-right: 0px !important;
  }

  #wrapperdashboard .fill-button span {
    display: none;
  }


  #sidebar-dashboard.custom-nav {
    position: fixed;
    width: 100% !important;
    height: 100vh;
    background: #fff;
    top: 0px;
    left: -600px;
    z-index: 99;
    transition: all 0.3s ease-in;
    padding-bottom: 150px;
  }

  #sidebar-dashboard.custom-nav.opensidebar {

    left: 0px;
    z-index: 9999;

  }

  .content-area-dashboard {
    width: 100%;
    margin-top: 30px;
    padding: 25px 0px;
  }

  div#wrapperdashboard {
    width: 95%;
    /* background: #E9EEF7; */
    background: #ffffff;
    border-radius: 0px;
    margin: 0px auto;
    margin-top: 20px;
    display: flex;
    box-shadow: 0px 0px 0px #c8c8c8;
  }

  #wrapperdashboard .navbar-expand-lg {
    position: fixed;
    width: 100%;
    background: #fff;
    height: 60px;
    left: 0px;
    top: 0px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
  }

  #wrapperdashboard .navbar-toggler {
    position: fixed;
    left: 15px;
    top: 14px;
    right: unset;
  }

  .mob-colls {
    flex-direction: column;
    margin-bottom: 10px;
  }

  #sidebar-dashboard.custom-nav.opensidebar ul li a {
    color: #000 !important;
    font-size: 17px;
  }

  #wrapperdashboard a.closesidebar {
    position: absolute;
    top: -2px;
    right: 18px;
    font-size: 30px;
    color: #030303;
    display: block;
  }

}