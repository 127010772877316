.carousel-indicators [data-bs-target] {
    background-clip: padding-box;
    background-color: #e713ff;
    border: 0;
    border-bottom: 10px solid transparent;
    border-top: 10px solid transparent;
    box-sizing: initial;
    cursor: pointer;
    flex: 0 1 auto;
    height: 3px;
   margin-left: 3px;
    margin-right: 3px;
    opacity: .5;
    padding: 0;
    text-indent: -999px;
    transition: opacity .6s ease;
    width: 18px;
   }
   .visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
    clip: rect(0,0,0,0)!important;
    border: 0!important;
    height: 1px!important;
    margin: -1px!important;
    overflow: hidden!important;
    padding: 0!important;
    position: relative !important;
    white-space: nowrap!important;
    width: 1px!important;
   }
   
   .carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
     color: #000;
   }
 
   .carousel-indicators .active {
     opacity: 1 !important;
   }
 
   a.carousel-control-prev {
     float: left;margin-left: 74%;
   }
 
   .carousel-indicators {bottom:-26px;}
 
   .carousel-control-next, .carousel-control-prev {
      align-items: center;      
      border: 2px solid #6c59ff;
      border-radius: 4px;
      bottom: 0;
      color: #000;     
      float: right;
      font-size: 14px;
      font-weight: 500;
      height: 33px;
      justify-content: center;
      margin-right: 4px;
      opacity: 1;
     padding: 3px 10px;
     text-align: center;
     text-decoration: none;
     transition: opacity .15s ease;
     width: 80px;
     z-index: 999;
     position: relative;
     margin-top: -20px;
     background: #fff;
     }