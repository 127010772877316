.form-wizard-feedback {
  color: #333;
  padding: 5px 10px;
  width: 100%;
}
.app-feedback {
  width: 100%;
  margin: 0 auto;
  border-radius: 6px;
  background: #ffffff;
  padding: 8px 20px 4px;
  border: solid 1px #ccc;
}

.feedback-row{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-wrapper {
  justify-content: center;
}

.feedback-heading {
  font-size: 15px;
  font-weight: 700;
  margin-top: 0px !important;
  margin-bottom: 10px;
  text-align: center;
  color: #545454;
}

.customFeedbackLabel{
  font-size: 14px;
  color: #3d3838;
}


.feedback-heading span {
 font-weight: bolder;
 font-size: 14px;
 color: #545454;
}

.container-feedback {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.feedback-wrapper {
  background: #ffffff;
  margin-bottom: 5px;
  padding: 0px 0px;
  border-radius: 0px;
}

.feedback-wrapper h6 {
  padding: 7px 0px;
  border-radius: 10px 10px 0px 0px;
  margin: 0px;
  font-size: 14px;
  color: #3d3838;
}
.item {
  width: 90px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}
.radio {
  display: none;
}
.radio ~ span {
  /* font-size: 1.1rem; */
 
  cursor: pointer;
  transition: 0.1s;
}

.radio ~ span img{
  width: 22px;
}

.radio:checked ~ span {
  filter: grayscale(0);
  /* font-size: 2rem; */
}
.radio:checked ~ span img{
  width: 30px;
}

.bae-mark img{
  width:200px;
  mix-blend-mode: darken;
}
.feedback-mark div {
  width: 100%;
  margin-right: 5px;
  padding: 3px 7px;
  border-radius: 4px;
  font-weight: bold;
  text-align: center;
 
}
.bae-mark {
  
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
}

.feedback-mark{
  background: #fffcd6;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: solid 2px #e8e4ac;
  border-radius: 4px;
}

.item:nth-child(1) .radio ~ span {
  background: url(../Images/one.jpg);
  width: 30px;
  height: 30px;
  display: block;
  background-size: contain;
}

.item:nth-child(2) .radio ~ span {
  background: url(../Images/two.jpg);
  width: 30px;
  height: 30px;
  display: block;
  background-size: contain;
}

.item:nth-child(3) .radio ~ span {
  background: url(../Images/three.jpg);
  width: 30px;
  height: 30px;
  display: block;
  background-size: contain;
}

.item:nth-child(4) .radio ~ span {
  background: url(../Images/four.jpg);
  width: 30px;
  height: 30px;
  display: block;
  background-size: contain;
}

.item:nth-child(5) .radio ~ span {
  background: url(../Images/five.jpg);
  width: 30px;
  height: 30px;
  display: block;
  background-size: contain;
}



.item:nth-child(1) .radio:checked ~ span {
  background: url(../Images/one-active.jpg);
  width: 30px;
  height: 30px;
  display: block;
  background-size: contain;
}

.item:nth-child(2) .radio:checked ~ span {
  background: url(../Images/two-active.jpg);
  width: 30px;
  height: 30px;
  display: block;
  background-size: contain;
}

.item:nth-child(3) .radio:checked ~ span {
  background: url(../Images/three-active.jpg);
  width: 30px;
  height: 30px;
  display: block;
  background-size: contain;
}

.item:nth-child(4) .radio:checked ~ span {
  background: url(../Images/four-active.jpg);
  width: 30px;
  height: 30px;
  display: block;
  background-size: contain;
}

.item:nth-child(5) .radio:checked ~ span {
  background: url(../Images/five-active.jpg);
  width: 30px;
  height: 30px;
  display: block;
  background-size: contain;
}
