

h6 {color: #505050; font-size: 15px;}

.bg-link {background: #f5f3f3;}
.text-gray {
  color: #666666;
}

form label {
  font-size: 13px;
  font-weight: 500;
} 

.s-product-category{
  height: 300px;
    overflow: auto;
}
.form-check-label, .form-check-input {
  cursor: pointer;
}
.form-switch {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0.6em;
  border-radius: 8px;
  border: 1px solid #a1a1a1;
  padding-left: 0.1em;
}
.form-switch .form-check-input {
  margin-right: 1em;
    float: right;
    width: 3.5em;
    height: 20px;
    margin-top: 0px;
}

.form-switch label {
    margin-left: 1rem;
    padding: 2px 0px;
}

.pagination a {color: #525252;}

.stv-radio-buttons-wrapper {
  clear: both;
  display: flex;
}

.stv-radio-button {
  position: absolute;
  left: -9999em;
  top: -9999em;
}
.stv-radio-button + label {
  float: left;
  padding: .6rem 0rem;
  cursor: pointer;
  border: 1px solid #c2c2c2;
  margin-right: -1px;
  color: #363636;
  background-color: #ffffff;
  padding-bottom: 8px !important;
  width: 100%;
  text-align: center;
 
}

.en-nn.stv-radio-button + label {
  width: 100%;
  text-align: center;
}

.stv-radio-button + label:first-of-type {
  border-radius: 0.3em 0 0 0.3em;
background: #fff;
  }

.stv-radio-button + label:last-of-type {
  border-radius: 0 0.3em 0.3em 0;
  background-color: #fff;
  
}
.stv-radio-button:checked + label {
  background-color: #646464;
  color: #fff;
}

.booking_confirm .num {
  color: #666666;
  font-size: 13px;
}

.booking_confirm .successfully_msg {
  font-weight: 500;
  font-size: 14px;
  margin-top: 5px;
}

.logo_booking {
  width: 170px;
}

.check_img {
  width: 60px;
}