body {
  margin: 0px;
  padding: 0px;
  background-color: #D8DEE7;
  font-size: 12px;
}

.displaystar-rating::before {
  position: absolute;
  content: "";
  width: 300px;
  background: transparent;
  z-index: 99;
  height: 50px;
  left: 0px;
  top: 0px;
}

.star-rating :checked~label:hover {
  color: #f90 !important;
}

.timezoneheight {
  height: 350px;
  overflow: auto;
}

a {
  cursor: pointer;
  text-decoration: none;
}

h6.pro-label.mb-2 {
  font-size: 14px;
  color: #3d3838;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 19px !important;

}

.modal-footer {
  justify-content: center;
}

.LunchNote {
  font-size: small;
}

.min-h {
  min-height: 350px;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  display: none;
}

.available-slot-invalid {
  border: solid 2px #dc3545 !important;
}

.morning-slot.form-group.position-relative {
  display: flex;
  border: solid 1px #efc45a;
  align-items: center;
  background: #fff;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 3px;  
  color: #000;
  padding: 0px;
}

.evening-slot.form-group.position-relative {
  display: flex;
  border: solid 1px #d3d2d0;
  align-items: center;
  background: #ededed;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 3px;
  color: #000;
  padding: 0px;
}

.slot-btn {
  background: #e8ffd8;
  margin: 3px 13px;
  padding: 3px 11px;
  color: #000;
  border: solid 2px #00ff2a;
  animation: slotblink 1s infinite ease-in;
  border-radius: 5px;
}

@keyframes slotblink {
  0% {
    border: solid 2px #00ff2a;
  }

  50% {
    border: solid 2px #07801b;
  }

  100% {
    border: solid 2px #00ff2a;
  }

}

.RightMessage {
  position: absolute;
  right: 9px;
  top: 37px;
  color: #095010;
  font-size: 11px;
  font-weight: 600;
}

.number-feedback .stv-radio-button+label.redCustom {
  background-color: #ec4645;
  color: white;
}

.number-feedback .stv-radio-button+label.amberCustom {
  background-color: #f6b532;
  color: white;
}

.number-feedback .stv-radio-button+label.greenCustom {
  background-color: #1aab28;
  color: white;
}

.LeftMessage {
  position: absolute;
  left: 0px;
  top: 37px;
  color: #ec4645;
  font-size: 11px;
  font-weight: 600;
}


.number-feedback .stv-radio-button:checked+label {
  background-color: #646464;
  color: #fff;
}

.number-feedback .stv-radio-button+label {
  float: left;
  padding: .5rem 0rem;
  cursor: pointer;
  border: 1px solid #c2c2c2;
  color: #363636;
  background-color: #ffffff;
  padding-bottom: 8px !important;
  text-align: center;
  border-radius: 5em;
  width: 34px;
  height: 34px;
  margin: 0px 5px;
}

.crnext:hover,
.crprev:hover {
  background: rgb(80 65 225) !important;
  color: #fff !important;
}

.star-rating {
  display: flex;
  flex-direction: row-reverse;
  font-size: 2em;
  justify-content: space-between;
  padding: 0px 0em;
  text-align: center;
  width: 8em;
  position: relative;

}

.star-rating input {
  display: none;
}

.star-rating label {
  color: #ccc;
  cursor: pointer;
  line-height: 26px;
  margin-bottom: 5px;
  font-size: 32px;
}

.star-rating :checked~label {
  color: #f90;
}

.star-rating label:hover,
.star-rating label:hover~label {
  color: #fc0;
}

.star-rating input[disabled]~label:hover~label {
  color: #ccc;
}

.nobookingtext {
  margin-top: 20px;
}

.nobookingtext30 {
  margin-top: 35px;
}



.mybookingbackbutton {
  top: 15px;
  position: absolute;
}

.closesidebar {
  display: block;
}

.form-wizard.form-pro-demo {
  padding: 0px 100px;
  height: 450px;
  overflow: auto;
}

.navbar-expand-lg .navbar-toggler {
  display: flex;
  color: #333;
  font-size: 14px;
  white-space: normal;
  justify-content: space-between;
}

.navbar-toggler {
  line-height: unset;
  border: transparent;
  cursor: pointer;
}

.custom-nav {
  position: absolute;
  width: 300PX;
  height: unset;
  top: 50px;
  opacity: 0;
  transition: all 0.3s ease-in;
  right: 30px;
  display: none;

}

.custom-nav.opensidebar {
  position: absolute;
  width: 300px;
  height: unset;
  background: #fff;
  top: 50px;
  right: 30px;
  z-index: 99;
  opacity: 1;
  transition: all 0.3s ease-in;
  -webkit-box-shadow: 0 24px 54px rgb(0 0 0 / 15%), 0 4.5px 13.5px rgb(0 0 0 / 8%);
  box-shadow: 0 24px 54px rgb(0 0 0 / 15%), 0 4.5px 13.5px rgb(0 0 0 / 8%);
  border-radius: 6px;
  display: block;
}


a.closesidebar {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 14px;
  color: #000;
  display: block;
  cursor: pointer;
  padding: 6px 10px;
}

a.closesidebar:hover {
  background: #ccc;
}

.custom-nav.opensidebar ul {
  width: 100%;
}

.custom-nav.opensidebar ul li {
  width: 100%;
}

.custom-nav.opensidebar ul li a {
  width: 100%;
  text-decoration: none;
  font-size: 15px;
  padding: 10px 18px !important;
}

.navbar-expand-lg .navbar-toggler.hide-desk {
  display: none;
}

.custom-nav.opensidebar ul li a:hover {
  background-color: #ccc;

}

.downloadButton {
  background: #5330b9;
  border-radius: 3px;
}

.downloadButton::after {
  display: none;
}

.admin-cust-link ul li a {
  font-weight: 500;
  background: #f1f4f6;
  display: block;
  color: #333;
  padding: 7px;
  border-bottom: solid 1px #fff;
}

.admin-cust-link ul li a:hover {
  font-weight: 500;
  background: #333;
  display: block;
  color: #fff;
  padding: 7px;
  border-bottom: solid 1px #fff;
}

.dropdown.admin-cust-link ul {
  margin: 0px;
  padding: 0px;
}

span.version {
  position: absolute;
  right: 10px;
  top: 100px;
  font-weight: 700;
  color: #7e17ff;
}

.opensidebar .user-circle {
  color: #000;
  font-size: 70px;
}

.form-wizard.form-pro-demo .row {
  border-radius: 5px;
  padding-bottom: 7px;
}

.opensidebar ul li a {
  color: #000 !important;
  font-size: 17px;
  cursor: pointer;
}

.welcome-user {
  font-size: 16px;
  margin: -5px 0px 0px 0px;
}

.user-profile-detail {
  color: #000;
  margin-bottom: 0px;
  border-bottom: solid 1px rgb(0 0 0 / 22%);
  padding: 10px 15px;
  display: flex;

}

.name-user {
  font-size: 14px;
  font-weight: 600;
}


.toast {
  margin-right: 10px;
  margin-bottom: 10px;
  --bs-toast-padding-x: 0.70rem;
  --bs-toast-padding-y: 0.2rem;
  --bs-toast-max-width: 300px;
}


.navbar-expand-lg {
  justify-content: flex-start;
  position: fixed;
  right: 15px;
  width: auto;
  z-index: 999;
  top: 20px;
}

::-webkit-scrollbar {
  width: 4px;


}

:hover::-webkit-scrollbar {
  width: 4px;

}



/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

div#wrapper {
  width: 95%;
  /* height: 95vh; */
  background: #fff;
  border-radius: 15px;
  margin: 0px auto;
  margin-top: 20px;
  display: flex;
}

div#wrapper-adminBookingList {
  background: #fff;
  border-radius: 15px;
  margin-top: 20px;
  display: flex;
}

.page-link .visually-hidden {
  display: none;
}

.scroll-cust {
  overflow-y: auto;
  overflow-x: hidden;
  height: 475px;
  margin-bottom: 15px;
  padding-right: 5px;
}

.calendar-icon {
  z-index: 0;
}

.react-datepicker__input-container input {
  background: transparent;
  position: relative;
  z-index: 2;
}



.loadermessage {
  margin-top: 40px;
  left: 50%;
  top: 50%;
  margin-left: -60px;
  position: absolute;
}

.loader {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top-color: #8f8f8f;
  height: 60px;
  width: 60px;
  position: absolute;
  margin-top: -30px;
  left: 50%;
  top: 50%;
  margin-left: -30px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


ul.cust-pagination {
  display: flex;
  list-style: none;
  margin-bottom: 0px;
}

ul.cust-pagination li.selected {
  background: #8a56fc;
  color: #fff;
  font-weight: 700;
}

ul.cust-pagination li.previous.disabled,
ul.cust-pagination li.previous {
  border-radius: 4px 0px 0px 4px;
}

ul.cust-pagination li.next {
  border-radius: 0px 4px 4px 0px;
}

ul.cust-pagination li {

  height: 34px;
  background: #fff;
  margin: 0px 0px;
  text-align: center;
  padding: 8px 13px;
  border-radius: 0px;
  border: solid 1px #ccc;
}

.fill-button {
  background: linear-gradient(291deg, rgb(80 65 225) 0%, rgb(123 95 211) 100%);
  color: #ffffff;
  padding: 10px 30px;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  border: solid 2px #6a58ff;
  margin-bottom: 5px;
  position: relative;
}

.mybookingButton {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 7px;
  background: linear-gradient(291deg, rgb(91 17 147) 0%, rgb(110 60 215) 100%);
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  display: flex;
  width: 2rem;
  height: 2rem;
  text-align: center;
  margin: 0.166rem;
  border-radius: 100px !important;
  align-items: center;
  justify-content: center;
  line-height: 2rem;
}

.react-datepicker__navigation {
  border-radius: 100px;
}

.react-datepicker__input-container input {
  background-color: transparent !important;
  z-index: 999999999;
  position: relative;
}



.no-bullets {
  list-style-type: none;
  margin: 0;
  padding: 0;
  float: right;
  margin-right: 10px;
}

.dashboardButton {
  background: linear-gradient(291deg, rgb(80 65 225) 0%, rgb(123 95 211) 100%);
  color: #ffffff;
  height: 34px;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 12px;
  position: relative;

}

.width100 {
  width: 100%;
}

.fill-button-greyed {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.fill-button:hover {
  background: linear-gradient(126deg, rgb(80 65 225) 0%, rgb(123 95 211) 100%);
  color: #fff;

}

.fill-button i {
  font-size: 20px;
  position: absolute;
  top: 5px;
  right: 15px;
  color: #fff;
}

.outline-button {
  background: #fff;
  color: #484848;
  padding: 10px 30px;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  border: solid 2px #6a58ff;
  margin-bottom: 5px;
}

.outline-button:hover {
  background: linear-gradient(291deg, rgb(80 65 225) 0%, rgb(123 95 211) 100%);
  color: #ffffff;
  padding: 10px 30px;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  border: solid 2px #6a58ff;
  margin-bottom: 5px;
}

label.next-arrow-new,
label.prev-arrow-new {
  background: #f6e6ff !important;
  color: #000;
}

#tablelist .dropdown-toggle::after {
  display: none;
}

#tablelist button#dropdownMenuButton1 {
  background: transparent;
  color: #000;
  border: solid #ccc;
  padding: 2px 10px;
}

.btn-small {
  height: 35px;
  display: flex;
  align-items: center;
}

#tablelist .dropdown-menu a {
  text-decoration: none;
  padding: 5px 10px;
  border-bottom: solid 1px #ccc;
  display: block;
  width: 100%;
  font-size: 16px;
}

#sidebar img {
  border-radius: 15px;
}

#sidebar {
  width: 329px;
  /* height: calc(100vh - 44px); */
  /* background: url(../images/bg.png) no-repeat; */
  border-radius: 10px;
  border-width: 0px 0px 0px 0px;
  margin: 7px;
  background-size: contain;
  min-width: 329px;
}

.left-banner {
  width: 90%;
  padding: 22px;
}

.content-area {
  position: relative;
  display: flex;
  width: calc(100% - 348px);
}

section.wizard-section {
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 500px;
}

.content-area-admin {
  position: relative;
  display: flex;
  width: calc(100% - 345px);
}

.content-area-adminPrint {
  position: relative;
  display: flex;
  width: calc(100%);
}

.content-area-admin section.wizard-section {
  height: unset;
}



.btn-fix-bottom {
  bottom: -20px;
  position: absolute;
}

.save-view-all {
  padding: 10px 30px;
  display: block;
  position: absolute;
  white-space: nowrap;
  background: #5344c3;
  right: 0px;
  top: -20px;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  text-decoration: none;

}

.save-view-all:hover {
  color: #fff;
}

div#tablelist table tr th:last-child,
div#tablelist table tr td:last-child {
  text-align: center;

}

div#tablelist table .fa-trash {
  color: #ff0000;
  font-size: 18px;
}

div#tablelist table .edit {
  font-size: 18px;
}

div#tablelist table tr td,
div#tablelist table tr th {
  padding: 10px 7px;
  vertical-align: middle;
}

.participants .stv-radio-button+label {
  padding: 0.6rem 1.9rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 13px;
  font-weight: normal;
  line-height: 1.5;
  color: #9a9a9a;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #666666;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}


.wizard-content-left {
  background-blend-mode: darken;
  background-color: rgba(0, 0, 0, 0.45);
  background-image: url("https://i.ibb.co/X292hJF/form-wizard-bg-2.jpg");
  background-position: center center;
  background-size: cover;
  height: 100vh;
  padding: 30px;
}

.wizard-content-left h1 {
  color: #ffffff;
  font-size: 38px;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
}

.form-wizard {
  color: #333;
  padding: 0px 100px;
}

.form-wizard .wizard-form-radio {
  display: inline-block;
  margin-left: 5px;
  position: relative;
}

.form-wizard .wizard-form-radio input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-color: #dddddd;
  height: 25px;
  width: 25px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.form-wizard .wizard-form-radio input[type="radio"]:focus {
  outline: 0;
}

.form-wizard .wizard-form-radio input[type="radio"]:checked {
  background-color: #fb1647;
}

.form-wizard .wizard-form-radio input[type="radio"]:checked::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: #ffffff;
  border-radius: 50%;
  left: 1px;
  right: 0;
  margin: 0 auto;
  top: 8px;
}

.form-wizard .wizard-form-radio input[type="radio"]:checked::after {

  /* webkit-animation: click-radio-wave 0.65s; */
  -moz-animation: click-radio-wave 0.65s;
  animation: click-radio-wave 0.65s;
  background: #000000;
  content: '';
  display: block;
  position: relative;
  z-index: 100;
  border-radius: 50%;
}

.form-wizard .wizard-form-radio input[type="radio"]~label {
  padding-left: 10px;
  cursor: pointer;
}

.form-wizard .form-wizard-header {
  text-align: center;
}

.addbooking-button {
  background: linear-gradient(291deg, rgb(80 65 225) 0%, rgb(123 95 211) 100%);
  color: #ffffff;
  display: inline-block;
  padding: 2px 4px 2px;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  font-weight: normal;
  border: solid 2px #6a58ff;
  border-radius: 6px;
}

.addbooking-button:hover  {
color: #fff;
text-decoration: none;
}

.addbooking-button:focus  {
  color: #fff;
  text-decoration: none;
  }


.form-wizard .form-wizard-next-btn,
.form-wizard .form-wizard-previous-btn,
.form-wizard .form-wizard-submit {
  background: linear-gradient(291deg, rgb(80 65 225) 0%, rgb(123 95 211) 100%);
  color: #ffffff;
  display: inline-block;
  min-width: 100px;
  min-width: 120px;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  border: solid 2px #6a58ff;
}

.form-wizard .form-wizard-next-btn:hover,
.form-wizard .form-wizard-next-btn:focus,
.form-wizard .form-wizard-previous-btn:hover,
.form-wizard .form-wizard-previous-btn:focus,
.form-wizard .form-wizard-submit:hover,
.form-wizard .form-wizard-submit:focus {
  color: #ffffff;
  opacity: 1;
  text-decoration: none;
  background: linear-gradient(114deg, rgb(80 65 225) 0%, rgb(123 95 211) 100%);
}

.form-wizard .wizard-fieldset {
  display: none;
}

.form-wizard .wizard-fieldset.show {
  display: block;
  height: 400px;
  overflow: auto;
  padding: 0px 1px;
  overflow-x: hidden;
}

.form-wizard .wizard-form-error {
  display: none;
  background-color: #d70b0b;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  visibility: hidden;
}

.form-group label,
label {

  font-weight: 600;
  padding-bottom: 4px;
}

.form-wizard .form-wizard-previous-btn {
  background: #fff !important;
  border: solid 2px #6958ff;
  color: #363636 !important;
}

.react-datepicker-popper {
  z-index: 999;
}

.form-wizard .form-control {
  font-weight: 500;
  padding: 6px 15px;
  color: #505050;
  background-color: #fff;
  border: solid 1px #666;
  height: 37px;
  font-size: 13px;
  border-radius: 0.3em;
}

.form-wizard.form-pro-demo .form-control {
  font-weight: 500;
  padding: 6px 15px;
  color: #505050;
  background-color: #fff;
  border: solid 1px #666;
  height: 55px;
  font-size: 13px
}

.form-wizard .form-control:disabled {
  background-color: #e9ecef !important;
  opacity: 1;
}

::placeholder {
  color: #232323 !important;
  opacity: 0.4 !important;
  font-size: 13px;
}

.form-wizard .form-control:focus {
  box-shadow: none;
}

.form-wizard .form-group {
  position: relative;
  margin: 0px 0 14px;
}

.form-wizard .wizard-form-text-label {
  position: absolute;
  left: 10px;
  top: 16px;
  transition: 0.2s linear all;
}

.form-wizard .focus-input .wizard-form-text-label {
  color: #d65470;
  top: -18px;
  transition: 0.2s linear all;
  font-size: 12px;
}

.form-wizard .form-wizard-steps {
  margin: 30px 0;
  position: absolute;
  left: -30px;
  top: 16px;
}

.form-wizard .form-wizard-steps li {
  width: 13%;
  position: relative;
  height: 114px;
}

.form-wizard .form-wizard-steps li:last-child {
  height: 0px;
}

.form-wizard .form-wizard-steps li::after {
  background-color: rgb(0 0 0 / 10%);
  content: "";
  height: 100%;
  left: 18px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-40%);
  width: 5px;
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
}

.form-wizard .form-wizard-steps li span {
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  height: 20px;
  line-height: 40px;
  position: relative;
  text-align: center;
  width: 20px;
  z-index: 1;
  border: solid 3px rgb(83 69 200 / 80%);
  margin-left: 11px;
}



.form-wizard .form-wizard-steps li.active span,
.form-wizard .form-wizard-steps li.activated span {
  background-color: #E713FF;
  color: #ffffff;
  margin-left: 12px;
  border: solid 3px #fff;
}

.form-wizard .form-wizard-steps li.active::after,
.form-wizard .form-wizard-steps li.activated::after {
  background-color: rgb(0 0 0 / 20%);
  left: 18px;
  width: 5px;
  border-color: #d65470;
}

.form-wizard .form-wizard-steps li.activated::after {
  width: 5px;
  border-color: #d65470;
}

.form-wizard .form-wizard-steps li:last-child::after {
  width: 50%;
  left: 0;
  display: none;
}

.form-wizard .wizard-password-eye {
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #8d5dfd;
  color: #fff;
  font-weight: 700;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  background-color: #808080;
  color: #fff;

}

.react-datepicker__month-container {
  float: left;
  border-right: 1px solid #aeaeae;
  width: 100%;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
  display: flex;
  justify-content: space-around;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
  display: flex;
  justify-content: space-around;
}

.react-datepicker__month {
  margin: 0rem !important;
}

.react-datepicker__navigation:hover *::before {
  border-color: #ffffff;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  top: 10px;
}

.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon:before,
.react-datepicker__year-read-view--down-arrow {
  border-color: #605d5d;
}


.react-datepicker__navigation-icon--next::before {
  left: -4px;
}

.react-datepicker__navigation-icon--previous::before {
  right: -4px;
}

.form-wizard .invaliddate .form-control {
  border: solid 2px #dc3545 !important;
  color: #000;
}

.react-datepicker__time-container {
  width: 69px;
  border-left: 0px solid #aeaeae;
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--selected,
.react-datepicker__month-text--in-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--selected,
.react-datepicker__quarter-text--in-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__year-text--in-range,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--selected {
  background-color: #8d5dfd;
  border-radius: 0.3rem;
  color: #fff;
}

.main-btn-fix {
  position: fixed;
  bottom: calc(100% - 578px);
  width: calc(100% - 606px);
  text-align: center;
}

@keyframes click-radio-wave {
  0% {
    width: 25px;
    height: 25px;
    opacity: 0.35;
    position: relative;
  }

  100% {
    width: 60px;
    height: 60px;
    margin-left: -15px;
    margin-top: -15px;
    opacity: 0.0;
  }
}

.is-invalid {
  border: solid 2px #dc3545 !important;
  color: #000;
}

.errorMessage {
  margin-top: .25rem;
  font-size: .875em;
  color: #dc3545;
}

.disable {
  display: none;
}

.invalid-feedback {
  padding-bottom: 0px !important;
}

.invalid-feedback .hide {
  display: none;
}

.modal-title {
  font-size: 18px;
}



.rs-picker-default .rs-picker-toggle.rs-btn-sm {
  height: 31px;
  margin-top: 4px;
  border: solid 2px #6c757d !important;
}

.rs-stack-item {
  width: 100%;
  overflow: visible !important;
}

.rs-picker-toolbar-right button.rs-btn.rs-btn-primary.rs-btn-sm {

  padding: 4px 15px;
  background: #8a56fc;
}





.booking_confirm {
  width: 72%;
  margin: 0px auto;
  padding: 20px;
  background: #f3f3f3;
  border-radius: 20px;
}

.vc-feedback i.fa-angle-right {
  font-size: 18px;
  top: 12px;
  right: 20px;
}

ul.cust-pagination li a {
  text-decoration: none;
}

/* -----admin login css-------------------- */
.login-wrapper {
  width: 100%;
  height: 100vh;
  background: url(../Images/bg-otp-login.jpg) fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-logo {
  width: 50%;
  margin: 0px auto;
}

.login-box {
  width: 355px;
  background: #fff;
  padding: 10px;

}


.otp-input-fields {
  display: flex;
  padding: 0px 100px;
}

h3.otp-heading {
  font-size: 22px;
}

p.info {
  font-size: 12px;
  font-weight: bold;
  color: #787878;
}

p.msg {
  font-weight: 400;
  margin-bottom: 8px;
}

span.timer {
  padding: 10px;
  display: inline-block;
  background: #ececec;
  margin-bottom: 10px;
  font-weight: 500;
  border-radius: 6px;
  width: 100px;
}

a.backarrow {
  cursor: pointer;
  position: absolute;
  left: 15px;
  transform: rotate(90deg);
  top: 10px;
  font-size: 20px;
}

.otp-disable-btn {
  background: #ccc;
  color: #000;
  margin-bottom: 10px;
  cursor: not-allowed !important;
  padding: 6px 11px;
}

.otp-enable-btn {
  background: linear-gradient(291deg, rgb(80 65 225) 0%, rgb(123 95 211) 100%);
  color: #fff;
  margin-bottom: 10px;
  cursor: pointer !important;
  padding: 6px 11px;
}

.otp-disable-btn:hover {
  background: #ccc;
}

.otp-enable-btn:hover {
  background: linear-gradient(291deg, rgb(80 65 225) 0%, rgb(123 95 211) 100%);
}

input.otp__digit {
  width: 100%;
  height: 30px;
  margin-bottom: 30px;
  border-bottom: solid 2px #ccc;
  margin-right: 10px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  box-shadow: none;
  border-width: 0px 0px 2px;
}

input.otp__digit:focus {
  border: none;
  outline: none;
  border-bottom: solid 2px #8e8e8e
}

/* ---------modal popup css for custom------------- */

.cust-modal .modal-dialog {
  max-width: 70%;
}

.cust-modal .modal-dialog .form-wizard {
  padding: 0px 0px;
}

.cust-modal .modal-dialog .modal-header {
  padding-left: 27px;
}

.rs-picker-toggle-wrapper {
  width: 242px !important;
}





input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}


.range-search-picker {
  height: 30px !important;
  padding: 2px 10px !important;
  margin-left: 4px !important;
  margin-top: 4px;
}

.for-mobile {
  display: none !important;
}

.for-desktop {
  display: block !important;
}

.rs-picker-toolbar-right {
  text-align: right;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #8a56fc;
}

/* .rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover
{
  background-color: #713be4;
} */

.rs-picker-menu .rs-calendar .rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content {
  background-color: #8a56fc;
}

.rs-calendar-table-cell-in-range::before {
  background-color: rgb(190 117 221 / 50%);
  background-color: rgb(219 196 242 / 50%);
}

.rs-calendar-table-cell-content:hover {
  background-color: rgb(219 196 242 / 50%) !important;
  color: #8a56fc !important;
}

.cnf-book-table tr td i {
  width: 20px !important;
  display: inline-block;
  padding: 0px;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  -webkit-box-shadow: inset 0 0 0 1px #7200ff;
  -webkit-box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
  box-shadow: inset 0 0 0 1px #3498ff;
  box-shadow: inset 0 0 0 1px #8a56fc;
}


.rs-calendar-table-cell.rs-calendar-table-cell-selected.rs-calendar-table-cell-selected-start div:hover {
  color: #FFF !important;
  background-color: #8a56fc !important;
}

.rs-calendar-table-cell.rs-calendar-table-cell-selected.rs-calendar-table-cell-selected-end div:hover {
  color: #FFF !important;
  background-color: #8a56fc !important;
}

.form-check-cust {
  background: #fff;
  padding: 5px 6px 0px 29px;
  height: 30px;
  border-radius: 4px;
  border: solid 1px #000;
  width: 102px;
  margin-right: 4px;
}

table {
  margin-bottom: 0px !important;
  padding-bottom: 0px;
}

table tr td {
  vertical-align: middle;
}

#example tr td:nth-child(2) div:nth-child(2) span {
  white-space: normal;
}

.pivoted div {
  display: flow-root;
}

#tablelist table tr th {
  border-bottom: solid 0px #000;
  background: unset !important;
  color: unset !important;
}

.form-select:disabled {
  background-color: #e9ecef;
  background-blend-mode: exclusion;
}

#example tr td:last-child {
  font-weight: bold;
  color: #5645df;
  position: relative;
  width: 5% !important;
}


.footer-booking {
  position: absolute;
  right: 35px;
  bottom: 0px;
  font-size: 9px;
}

#root {
  position: relative;
}

.star-rating input[disabled]~label:hover {
  color: #cccccc;
}

@media screen and (max-width: 1024px) {
  .main-btn-fix {
    position: fixed;
    top: calc(100% - 96px);
    width: calc(100% - 606px);
    text-align: center;
    bottom: unset;
  }

  .content-area-admin {
    width: 100%;
  }
}

@media screen and (max-width: 720px) {

  .addbooking-button {
    background: linear-gradient(291deg, rgb(80 65 225) 0%, rgb(123 95 211) 100%);
    color: #ffffff;
    display: inline-block;
    min-width: 120px;
    padding: 3px 4px;
    text-align: center;
    border-radius: 5px;
    text-decoration: none;
    font-size: 14px;
    font-weight: normal;
    border: solid 2px #6a58ff;
    margin-left: auto;
    border-radius: 6px;
    margin-top: 9px;
  }

}

@media screen and (max-width: 540px) {

  .addbooking-button {
    background: linear-gradient(291deg, rgb(80 65 225) 0%, rgb(123 95 211) 100%);
    color: #ffffff;
    display: inline-block;
    min-width: 120px;
    padding: 3px 4px;
    text-align: center;
    border-radius: 5px;
    text-decoration: none;
    font-size: 14px;
    font-weight: normal;
    border: solid 2px #6a58ff;
    margin-left: auto;
    border-radius: 6px;
    margin-top: 9px;
  }

}

@media screen and (max-width: 768px) {

  .admin-cust-link ul li a,
  .admin-cust-link ul li a:hover {
    height: 100%;
  }

  .ymt {
    flex-direction: column;
  }

  .LunchNote {
    font-size: small;
    margin-bottom: 10px;
  }

  .ymt .form-select {
    margin-bottom: 10px;
  }

  .downloadButton {
    background: #5330b9;
    border-radius: 3px;
    display: none !important;
  }

  span.version {
    position: absolute;
    right: 10px;
    top: 100px;
    font-weight: 700;
    color: #fff;
  }

  div#tablelist {
    margin-bottom: 50px;
    width: 100%;
    overflow: auto;
  }

  .content-area {
    position: relative;
    display: flex;
    width: 100%;
  }



  #example tr td:last-child {
    font-weight: bold;
    color: #5645df;
    position: relative;
    width: 100% !important;
  }



  .rs-picker-toolbar-right button.rs-btn.rs-btn-primary.rs-btn-sm {
    text-indent: unset;
    padding: 4px 15px;
    background: #8a56fc;
  }

  .rs-picker-toolbar-right button.rs-btn.rs-btn-primary.rs-btn-sm::after {
    display: none !important;
  }

  .rs-picker-toolbar-right button.rs-btn.rs-btn-primary.rs-btn-sm::before {
    display: none !important;
  }

  .rs-picker-daterange-menu .rs-picker-daterange-panel-show-one-calendar .rs-picker-toolbar {
    max-width: 100%;
  }

  .for-mobile {
    display: block !important;
  }

  .for-desktop {
    display: none !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .navbar-toggler.hide-desk {
    display: block;
  }




  #example tr {
    margin-bottom: 7px;
  }

  .logo-img {
    position: relative;
    top: -17px;
  }

  .navbar-expand-lg {
    /* flex-wrap: nowrap; */
    justify-content: flex-start;
    position: static;
    right: 15px;
    width: auto;
    top: 20px;
    z-index: 999 !important;
  }

  .navbar-toggler {
    position: absolute;
    left: 15px;
    top: 28px;
    right: unset;
  }

  .custom-nav {

    position: fixed;
    width: 100%;
    height: 100vh;
    background: #ccc;
    top: 0px;
    left: -600px;
    z-index: 99;
    transition: all 0.3s ease-in;

  }

  a.closesidebar {
    position: absolute;
    top: -2px;
    right: 18px;
    font-size: 30px;
    color: #fff;
    display: block;
  }


  .welcome-user {
    font-size: 14px;
    color: #333;
    font-weight: 600;
  }

  .custom-nav.opensidebar {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: linear-gradient(309deg, rgb(68 19 88) 0%, rgb(145 86 240) 100%);
    top: 0px;
    left: 0px;
    z-index: 99;
    transition: all 0.3s ease-in;

  }

  .opensidebar .user-circle {
    color: #fff;
    font-size: 30px;
  }

  .user-profile-detail {
    text-align: center;
    color: #fff;
    margin-bottom: 15px;
    border-bottom: solid 1px rgb(0 0 0 / 22%);
    padding: 20px 30px;
    display: block;
  }

  .fa-arrow-right-from-bracket,
  .fa-question-circle,
  .fa-ticket-alt,
  .fa-chart-pie,
  .fa-key {

    width: 50px;

  }

  .fa-arrow-right-from-bracket {
    width: 50px;
  }

  .opensidebar ul {
    margin: 0px;
    padding: 0px;

  }

  .opensidebar ul li a {
    color: #fff !important;
    font-size: 17px;
  }

  table#example th,
  table#example td {
    padding: 0px 6px !important;
    padding-left: 0px !important;
  }

  section.wizard-section {
    width: 100%;
    overflow-y: unset;
    overflow-x: unset;
    height: unset;
  }

  .loader {
    margin-top: 200px;
  }

  .loadermessage {
    margin-top: 270px;
  }

  .calendar-icon2 {
    top: 39px !important;
    font-size: 23px !important;
  }

  .calendar-icon {
    top: 13px !important;
    font-size: 23px !important;
  }

  .d-flex.button-wrapper button,
  .d-flex.button-wrapper a {
    width: 100%;
    margin: 4px 0px !important;
  }

  #example .dropdown-menu li a {
    padding: 5px 8px 0 !important;
    white-space: normal;
    text-align: center;
    height: unset !important;
  }

  .customFilter {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .customFilter .dropdown {
    margin-bottom: 5px;
  }

  .rs-picker-toggle-wrapper {
    margin-bottom: 5px;
    width: 100% !important;
  }

  .range-search-picker {
    margin-bottom: 5px;
  }

  .rs-anim-fade.rs-anim-in {
    width: 90%;
    overflow: auto;
    left: 21.3333px !important;
  }



  ul.cust-pagination {
    width: 100%;
    align-items: flex-start;
    margin: 10px 0px 0px 0px !important;
    padding: 0px;
  }


  .wizard-content-left {
    height: auto;
  }

  .pivoted div span {
    float: right;
    width: 60%;
    white-space: normal;
  }

  .pivoted span {
    float: right;
    width: 60%;
  }

  #example .dropdown-menu li {
    width: 48%;
    margin: 2px;
  }

  #example .dropdown-menu {
    display: flex;
    flex-wrap: wrap;
  }

  .cust-modal .modal-dialog {
    max-width: 100%;
  }
}


/* ---------------slider css---------------- */

.slider {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin-bottom: 15px;
}

/*Окно со слайдами*/
.slider-content {
  position: relative;
  width: 100%;
}

/*Контейнер для слайдов (передвигаем)*/
.slider-content-wrapper {
  display: flex;
  height: 300px;
  transition: transform 0.2s ease-in-out;
  Z-INDEX: 99;
  position: relative;
}

/*Слайд*/
.slider-content__item {
  flex: 1 0 100%;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-1 {
  background-color: #fff
}

.image-2 {
  background-color: #fff;
}

.image-3 {
  background-color: #fff;
}



/* Блок с контролами */
.slider-controls {
  padding: 20px;
  text-align: center;
  position: absolute;
  bottom: -6px;
  right: -19px;
  z-index: 99999;
}

.auto-control {
  display: none;
}

.prev-control {
  display: none;
}

/* Блок с контролами внутри окна */
.slider-content__controls {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%;
  z-index: 999;
  /*transform: translateY(-50%);*/
  /*padding:0 15px;*/
}

/* Arrows */
.prev-arrow,
.next-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: rgba(0, 0, 0, 0.3);
  width: 20px;
  transition: all 0.3s;
}

.prev-arrow {
  left: 20px;
  display: none;
}

.next-arrow {
  right: 20px;
  display: none;
}

.prev-arrow:hover,
.next-arrow:hover {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.7);
}

/* Dots */
.dots {
  position: absolute;
  display: flex;
  left: 50%;
  transform: translateX(-50%);
  bottom: 50%;
}

.dot {
  cursor: pointer;
  width: 14px;
  height: 3px;
  margin-right: 4px;
  background-color: rgb(231 19 255 / 20%);
  /* box-shadow: 0 0 5px 0px rgb(0 0 0 / 90%); */
  border-radius: 10px;
  transition: all 0.3s;
}

.dot:last-child {
  margin-right: 0;
}

.dot:hover {
  background-color: #fff;
}

.dot--active {
  background-color: #e713ff;
}

/* Buttons */
button {
  cursor: pointer;
  margin-right: 8px;
  border: none;
  border-radius: 4px;
  padding: 10px;
  background-color: #3066BE;
  color: #FFF;
  transition: all 0.5s;
}

button:last-child {
  margin-right: 0;
}

button:hover {
  background-color: #60AFFF;
}

button:focus {
  outline: none;
}

.next-control {
  border: solid 2px #6730FD;
  padding: 5px 36px;
  font-size: 14px;
  border-radius: 5px;
  color: #333;
  font-weight: 500;
  cursor: pointer;
}

/* Mods */
.disabled {
  background-color: #DCCFCF;
  color: #B0A8A8;
  cursor: default;
  pointer-events: none;
  opacity: 0.4;
}

.d-none {
  display: none;
}

.active {
  opacity: 1;
}

.centered {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cnf-book-table {
  background-color: #f7f7f7;
  border: solid 1px #c9c9c9;
  margin-bottom: 15px;
}

.loop-table-main {
  display: flex;
  flex-wrap: wrap;
}

.loop-table-div {
  width: 46%;
  margin-right: 20px;
  margin-bottom: 0px;
  position: relative;
}

.cnf-book-table tr td {
  border-bottom: solid 1px #c9c9c9;
  padding: 6px 15px;
}

.booking-area {
  padding: 10px 50px;
}

.edit-booking {
  color: #000;
  border: solid 1px #a7a7a7;
  padding: 4px 24px;
  border-radius: 40px;
  text-decoration: none;
  background: #fff;
  margin-right: 5px;
}

div#confirm h2 {
  font-size: 18px;
  margin-top: 10px;
}

div#confirm {
  position: absolute;
  width: 100%;
  height: 93%;

  background: rgb(0 0 0 / 50%);
  top: 0px;
  text-align: center;
  color: #fff;
  padding: 31px 0px;
}

div#confirm img {
  background: #fff;
  border-radius: 210px;
}


.cnf-booking {
  color: #fff;
  border: solid 1px #6E03FB;
  padding: 4px 24px;
  border-radius: 40px;
  text-decoration: none;
  background: #6E03FB;
  margin-right: 5px;
}

/* .cnf-book-table tr td .fa-user, .cnf-book-table tr td .fa-envelope, .cnf-book-table tr td .fa-clock, .cnf-book-table tr td .fa-users, .cnf-book-table tr td .fa-burger {
  padding: 0px 0px;
  min-width: 30px;
} */

.booking_confirm .num {
  color: #666666;
  font-size: 18px;
}

.booking_confirm .num span {
  color: #38148a;
}

.booking_confirm .successfully_msg {
  font-size: 17px;
  color: #484848;
}


.form-check-input:checked {
  background-color: #5645df;
  border-color: #391f99;
}

.react-datepicker-wrapper {

  width: 100%;
}

table.dataTable.nowrap th,
table.dataTable.nowrap td {
  white-space: nowrap;
  vertical-align: middle;
  line-height: 25px;
  font-weight: 500;
  font-size: 11px;
}

table.dataTable.nowrap td span {
  /* color: #6e30ee; */
  font-weight: 400;
  font-size: 11px;
}

#example thead {
  visibility: hidden;
  opacity: 0;
}

table#example th,
table#example td {
  white-space: nowrap;
  vertical-align: middle;
  line-height: 22px;
  font-size: 12px;
  border-top: solid 1px #ededed;
  color: #262d38;
  padding: 9px 4px;
}

td.confirm:last-child::after {
  border-radius: 0;
  border-width: 4px;
  border-right-color: #22c839 !important;
  border-top-color: #22c839 !important;
  position: absolute;
  right: 0;
  border-style: solid;
  content: '';
  top: 0px;
}

td.tb-confirm:last-child::after {
  border-radius: 0;
  border-width: 4px;
  border-right-color: #ff9e64 !important;
  border-top-color: #ff9e64 !important;
  position: absolute;
  right: 0;
  border-style: solid;
  content: '';
  top: 0px;
}

td.cancel:last-child::after {
  border-radius: 0;
  border-width: 4px;
  border-right-color: #ff5151 !important;
  border-top-color: #ff5151 !important;
  position: absolute;
  right: 0;
  border-style: solid;
  content: '';
  top: 0px;
}

td.concluded:last-child::after {
  border-radius: 0;
  border-width: 4px;
  border-right-color: #a722fe !important;
  border-top-color: #a722fe !important;
  position: absolute;
  right: 0;
  border-style: solid;
  content: '';
  top: 0px;
}

.confirm {
  border-left: solid 0px #22c839;
}

.cancel {
  border-left: solid 0px #ff5151;
}

.concluded {
  border-left: solid 0px #a722fe;
}

.tb-confirm {
  border-left: solid 0px #ff9e64;
}

span.concluded {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 5px 0px 0 0px;
  display: inline-block;
  background: #a722fe;
}

span.confirmed {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 5px 0px 0 0px;
  display: inline-block;
  background: #22c839;
}

span.cancel {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 5px 0px 0 0px;
  display: inline-block;
  background: #ff5151;
}

span.tb-confirm {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 5px 0px 0 0px;
  display: inline-block;
  background: #ff9e64;
}

.notation {
  display: flex;
  list-style: none;
  justify-content: end;
}

.notation li {
  margin-right: 7px;
}

#example {
  margin-top: 0px !important;
}

#example tr {

  background: #fff;
  border-radius: 5px;
  box-shadow: 8px 8px 43px 9px rgb(137 169 175 / 13%);
  transition: all 0.2s;
}

#example tr:hover {
  box-shadow: 0px 0px 25px 0px rgb(137 169 175 / 50%);
  /* transform: perspective(72px) translatex(1px); */
  z-index: 99;
}

.table>:not(caption)>*>* {
  padding: 0.8rem 0.5rem;
}

.dropdown-menu {
  font-size: 0.8rem;

}

.dropdown-menu a {
  font-weight: 500;
}

#example .dropdown-toggle {
  background: transparent;
  color: #000;
  padding: 0px;
}

#example .dropdown-toggle::after {
  display: none;
}

#example .dropdown-menu {
  z-index: 9999;
  padding: 0px;
  min-width: auto;
  background-color: #fff;
}

#example .dropdown-menu li {
  padding: 0px 0px;
  border-bottom: solid 1px #ccc;
  background: #f1f4f6;
}

#example .dropdown-menu li a {
  text-decoration: none;
  color: #333;
  font-size: 13px;
  /* font-weight: bold; */
  padding: 7px 20px 0px 8px;
  display: block;
  height: 33px;
}

#example .dropdown-menu li a:hover {
  background-color: #333;
  color: #fff;
}

.rating-heading {
  background: #ededed;
  border-radius: 6px;
  padding: 10px;
}

.date-bold {
  font-weight: 700 !important;
}

.vc-feedback {
  display: flex;
  flex-direction: column;
}

.vc-feedback-disable button {
  margin-bottom: 20px;
  text-align: left;
  height: 52px;
  background: #8050e7;
  color: #f5f2f2;
  /* border: solid 2px #6e6e6e; */
  font-weight: bold;
  margin-right: 0px;
  width: 100%;
  opacity: 1;
  cursor: pointer;
  position: relative;
}

.vc-feedback-disable button:hover {
  background: #8e60fd;
  color: #f5f2f2;

}

.vc-feedback-disable i,
.vc-feedback i {
  font-size: 26px;
  padding-top: 0px;
  position: absolute;
  right: 14px;
  top: 6px;

}



.vc-feedback button {
  margin-bottom: 20px;
  text-align: left;
  height: 52px;
  background: #e3d7fd;
  color: #000;
  font-weight: bold;
  margin-right: 0px;
  position: relative;
}

.vc-feedback button span {
  opacity: 1;
}

.vc-feedback button:hover {
  background: #e3d7fd;
  color: #000;
}

.calendar-icon {
  position: absolute;
  z-index: 99;
  right: 5%;
  top: 20%;
  font-size: 17px;
}

footer.text-center {
  margin: 10px 10px;
  font-weight: 700;
}



.calendar-icon2 {
  position: absolute;
  z-index: 99999999;
  right: 21px;
  top: 33px;
  font-size: 17px;
}

.react-datepicker {
  width: 317px;
  overflow: auto;
  overflow-x: hidden;
  display: block;
  height: 250px;
}

.stv-radio-buttons-wrapper.number-feedback {
  width: 100%;
  justify-content: space-between;
}

@media only screen and (max-width: 1366px) {

  .calendar-icon {
    position: absolute;
    z-index: 99;
    right: 5%;
    top: 20%;
    font-size: 17px;
  }
}

@media only screen and (max-width: 1024px) {

  .calendar-icon {
    position: absolute;
    z-index: 99;
    right: 5%;
    top: 30%;
    font-size: 17px;
  }
}

/* -----------css for iphone portrait ------------------- */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {

  body {
    background-color: #ffffff;
  }

  .form-wizard.form-pro-demo {
    height: unset;
    padding: 0px 10px;
    overflow: unset;
  }

  .addbooking-button {
    background: linear-gradient(291deg, rgb(80 65 225) 0%, rgb(123 95 211) 100%);
    color: #ffffff;
    display: inline-block;
    min-width: 120px;
    padding: 3px 4px;
    text-align: center;
    border-radius: 5px;
    text-decoration: none;
    font-size: 14px;
    font-weight: normal;
    border: solid 2px #6a58ff;
    margin-left: auto;
    border-radius: 6px;
    margin-top: 9px;
  }

  .image-1, .image-2, .image-3 {
    padding-bottom: 30px;
  }

  .evening-slot.form-group.position-relative,
  .morning-slot.form-group.position-relative {
    justify-content: center;
  }

  .LunchNote {
    font-size: small;
    margin-bottom: 10px;
  }

  .stv-radio-buttons-wrapper.number-feedback {
    width: 100%;
  }

  .button-wrapper {
    flex-direction: column;
  }

  .sparkboxes .box .details {
    color: #fff;
    position: absolute;
    -webkit-transform: scale(.7) translate(-22px, 4px);
    transform: scale(.7) translate(-31px, -8px);
  }

  .scroll-cust {
    height: unset;
  }

  .number-feedback .stv-radio-button+label {
    width: 23px;
    height: 23px;
    padding-top: 3px;
    font-size: 12px;
  }

  .LeftMessage {
    position: absolute;
    left: 5px;
    top: 24px;
    background: #f3ffda;
  }

  .RightMessage {
    position: absolute;
    right: 5px;
    top: 24px;
    background: #f3ffda;
  }

  .booking_confirm {
    width: 100%;
  }

  * .react-datepicker-wrapper {

    width: 100%;
    z-index: 9;
    position: relative;
  }

  .react-datepicker__day--highlighted,
  .react-datepicker__month-text--highlighted,
  .react-datepicker__quarter-text--highlighted,
  .react-datepicker__year-text--highlighted {
    background-color: grey;
    color: #fff;

  }

  .react-datepicker__month-container {
    float: left;
    border-right: 1px solid #aeaeae;
    width: 100%;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    white-space: nowrap;
    display: flex;
    justify-content: space-around;
  }

  .react-datepicker__day-names {
    margin-bottom: -8px;
    display: flex;
    justify-content: space-around;
  }

  .react-datepicker__month {
    margin: 0rem !important;
  }

  .react-datepicker__navigation:hover *::before {
    border-color: #ffffff;
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    top: 10px;
  }

  .react-datepicker__navigation-icon--next::before {
    left: -5px;
  }

  .react-datepicker__navigation-icon--previous::before {
    right: -5px;
  }

  .booking-area {
    padding: 10px 10px;
  }

  .loop-table-div {
    width: 100%;
    margin-right: 6px;
    margin-bottom: 0px;

  }

  #sidebar {
    display: none;
  }

  .form-wizard {
    color: #888888;
    padding: 5px 10px;
  }


  .form-wizard .form-wizard-steps li.active::after,
  .form-wizard .form-wizard-steps li.activated::after {
    left: 17%;
    width: 100%;

  }

  .form-wizard .form-wizard-steps li::after {

    content: "";
    height: 5px;
    left: 0;
    position: absolute;
    right: 0;
    top: 28%;
    transform: translateX(47%);
    width: 100%;


  }

  .form-wizard .form-wizard-steps {
    margin: 30px 0;
    position: static;
  }

  .form-wizard .form-wizard-steps li {
    float: left;
    width: 20%;
    position: relative;
    height: 27px;
  }

  .btn-fix-bottom {
    position: relative !important;
    bottom: 0px;
  }





  .mob-margin {
    margin-bottom: 14px;
  }

  .form-wizard .form-wizard-next-btn,
  .form-wizard .form-wizard-previous-btn,
  .form-wizard .form-wizard-submit {
    width: 49%;
  }


  .slider-content-wrapper {
    display: flex;
    height: 350px;
    transition: transform 0.2s ease-in-out;
    Z-INDEX: 99;
    position: relative;
  }

  .form-switch {
    margin-bottom: 1.5em !important;

  }

  .form-group label,
  label {
    font-size: 15px;
  }

  .form-wizard .form-control {
    height: 50px !important;
    color: #272727;
    font-size: 15px;
  }

  form label {
    font-size: 15px !important;

  }

  .form-switch label {
    padding: 5px 5px !important;
    margin-left: 0rem;
  }

  .form-switch .form-check-input {
    margin-top: 3px !important;
    width: 4em !important;
    height: 24px !important;
  }

  .participants .stv-radio-button+label {
    padding: 0.6rem 2.8rem !important;
  }

  .carousel {
    position: relative;
  }

  .carousel-indicators {
    bottom: -20px;
    left: -100px;
  }

  .carousel-control-next,
  .carousel-control-prev {
    margin-top: -24px;
    z-index: 9999;
  }

  a.carousel-control-prev {
    float: left;

  }

  .dropdown-menu-buttons {
    display: flex !important;
    width: 100%;
    margin: 0px;
    padding: 0px;
    position: static;
  }

  .dropdown-menu-buttons li {
    width: 100%;
    list-style-type: none;
    text-align: center;
    border: solid 2px #027afb;
  }

  .dropdown-menu {
    width: 100%;
    position: static;
  }

  #example .dropdown-menu li {
    width: 100%;
    text-align: center;
    border: solid 2px #027afb;
  }

  table.dataTable>tbody>tr.child ul.dtr-details {
    width: 100%;
  }

  #example .dropdown-toggle {
    display: none;
  }

}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #000;
  font-size: 13px;
  font-weight: 700 !important;
}


/* -----------css for iphone landscap ------------------- */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  #sidebar {
    height: calc(100vh - 0px);
    min-width: 223px;
    width: 308px;

  }

}

/* -----------css for iPad mini landscap ------------------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {

  .form-wizard {

    padding: 5px 20px;
  }

  .content-area {
    position: relative;
    display: flex;
    width: 100%;
  }

  section.wizard-section {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 800px;
  }

  .addbooking-button {
    background: linear-gradient(291deg, rgb(80 65 225) 0%, rgb(123 95 211) 100%);
    color: #ffffff;
    display: inline-block;
    min-width: 120px;
    padding: 3px 4px;
    text-align: center;
    border-radius: 5px;
    text-decoration: none;
    font-size: 14px;
    font-weight: normal;
    border: solid 2px #6a58ff;
    border-radius: 6px;
    margin-left: auto;
  }

  .form-wizard .wizard-fieldset.show {
    display: block;
    height: 500px;
    overflow: auto;
    padding: 0px 1px;
    overflow-x: hidden;
  }

  .main-btn-fix {
    position: fixed;
    top: calc(61% - 76px);
    width: calc(100% - 65px);
    text-align: center;
    bottom: unset;
  }

  .wrapper-admin #sidebar {
    display: none;
  }

  .btn-fix-bottom {
    position: relative !important;
    bottom: 0px;
  }


  .stv-radio-button+label {
    padding: 0.6rem 0.8rem !important;
  }

  .form-wizard .form-group {
    margin: 20px 0 14px !important;
  }

  #sidebar {
    height: calc(100vh - 65px);
    min-width: 415px;
  }

  .participants .stv-radio-button+label {
    padding: 0.6rem 1.8rem !important;
  }
}


/* -----------css for iPad mini portrait ------------------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  #sidebar {
    display: none;
  }

  .responsiveTable table,
  .responsiveTable thead,
  .responsiveTable tbody,
  .responsiveTable th,
  .responsiveTable td,
  .responsiveTable tr {
    display: block;
  }

  .responsiveTable td.pivoted {
    border: none !important;
    position: relative;
    padding-left: calc(0% + 10px) !important;
    text-align: left !important;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  .dropdown-menu {
    width: 100%;
    position: static;
  }

  #example .dropdown-toggle {
    display: none;
  }

  .dropdown-menu-buttons {
    display: flex;
    width: 100%;
    margin: 0px;
    padding: 0px;
    position: static;
  }

  .react-datepicker-wrapper {

    width: 97%;
  }

  .form-wizard {
    color: #888888;
    padding: 5px 10px;
  }



  .form-wizard .form-wizard-steps li.active::after,
  .form-wizard .form-wizard-steps li.activated::after {
    left: 17%;
    width: 100%;

  }

  .form-wizard .form-wizard-steps li::after {

    content: "";
    height: 5px;
    left: 0;
    position: absolute;
    right: 0;
    top: 28%;
    transform: translateX(47%);
    width: 100%;


  }

  .form-wizard .form-wizard-steps {
    margin: 30px 0;
    position: static;
  }

  .form-wizard .form-wizard-steps li {

    float: left;
    width: 20%;
    position: relative;
    height: 27px;
  }

  .btn-fix-bottom {
    position: relative !important;
    bottom: 0px;
  }

  .form-select {
    height: 50px !important;
  }



  .mob-margin {
    margin-bottom: 14px;
  }

  .form-wizard .form-wizard-next-btn,
  .form-wizard .form-wizard-previous-btn,
  .form-wizard .form-wizard-submit {
    width: 49%;
  }

  .stv-radio-button+label {
    float: left;
    padding: 0.6rem 0.75rem !important;
    cursor: pointer;
    border: 1px solid #c2c2c2;
    margin-right: -1px;
    color: #363636;
    background-color: #ffffff;
    font-size: 12px !important;
  }

  .slider-content-wrapper {
    display: flex;
    height: 350px;
    transition: transform 0.2s ease-in-out;
    Z-INDEX: 99;
    position: relative;
  }

  .form-switch {
    margin-bottom: 1.5em !important;

  }

  .form-group label {
    font-size: 15px;
  }

  .form-wizard .form-control {
    height: 50px !important;
    color: #272727;
    font-size: 15px;
  }

  form label {
    font-size: 15px !important;

  }

  .form-switch label {

    padding: 5px 5px !important;
  }

  .form-switch .form-check-input {
    margin-top: 3px !important;
    width: 4em !important;
    height: 24px !important;
  }

  .participants .stv-radio-button+label {
    padding: 0.6rem 2.8rem !important;
  }

}

/* -----------css for below 768 devices ------------------- */

@media only screen and (max-device-width: 768px) {
  #sidebar {
    display: none;
  }

  .form-wizard .form-wizard-steps li.active span,
  .form-wizard .form-wizard-steps li.activated span {
    background-color: #E713FF;
    color: #ffffff;
    margin-left: 12px;
    border: solid 0px #fff;
  }

  .form-wizard .form-wizard-steps li.activated::after {
    width: 5px;
    border-color: transparent;
  }

  .form-wizard .form-wizard-steps li.active::after,
  .form-wizard .form-wizard-steps li.activated::after {
    background-color: rgb(0 0 0 / 20%);
    left: 18px;
    width: 5px;
    border-color: transparent;
  }

  .form-wizard .wizard-fieldset.show {
    display: block;
    height: unset;
    overflow: unset;
    overflow-x: unset;
    padding: 0px 1px;
  }

  section.wizard-section {
    height: unset;
  }

  .main-btn-fix {
    position: static;
    bottom: unset;
    width: 100%;
    text-align: center;
    padding: 0px;
  }

  .form-wizard {
    color: #333;
    padding: 5px 10px;
  }

  .footer-booking {
    bottom: -40px;
  }



  .form-wizard .form-wizard-steps li.active::after {
    left: 17%;
    width: 100%;

  }

  .form-wizard .form-wizard-steps .activated::after {
    left: 17%;
    width: 100%;
  }

  .form-wizard .form-wizard-steps li::after {

    content: "";
    height: 5px;
    left: 0;
    position: absolute;
    right: 0;
    top: 28%;
    transform: translateX(47%);
    width: 100%;


  }

  .form-wizard .form-wizard-steps {
    margin: 0px 0 10px;
    position: static;
  }

  .form-wizard .form-wizard-steps li {
    float: left;
    width: 20%;
    position: relative;
    height: 27px;
  }

  .btn-fix-bottom {
    position: relative !important;
    bottom: 0px;
  }

  .form-select {
    height: 50px !important;
  }

  .form-wizard .form-control,
  .form-control {
    height: 50px !important;
  }

  .cancelBox {
    height: 110px !important;
  }

  .feedback-text-area {
    height: 110px !important;
  }

  .mob-margin {
    margin-bottom: 14px;
  }

  .form-wizard .form-wizard-next-btn,
  .form-wizard .form-wizard-previous-btn,
  .form-wizard .form-wizard-submit {
    width: 48%;
  }





  .slider-content-wrapper {
    display: flex;
    height: 350px;
    transition: transform 0.2s ease-in-out;
    Z-INDEX: 99;
    position: relative;
  }

  .form-switch {
    margin-bottom: 1.5em !important;

  }

  .form-group label {
    font-size: 15px;
  }

  .form-wizard .form-control {
    color: #272727;
    font-size: 14px;
    padding: 0px 6px;
  }

  form label {
    font-size: 15px !important;

  }

  .form-switch label {

    padding: 5px 5px !important;
  }

  .form-switch .form-check-input {
    margin-top: 3px !important;
    width: 6em !important;
    margin-left: 1px;
    margin-right: 10px;
    height: 24px !important;
  }


  #example .dropdown-menu li {
    width: 48% !important;
    text-align: center;
    border: solid 2px #027afb;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #example .dropdown-menu li:first-child:nth-last-child(1),
  #example .dropdown-menu li:first-child:nth-last-child(1)~li {
    width: 100% !important;
  }

  #example .dropdown-menu li:first-child:nth-last-child(3),
  #example .dropdown-menu li:first-child:nth-last-child(3)~li {
    width: 32% !important;
  }

  table.dataTable>tbody>tr.child ul.dtr-details {
    width: 100%;
  }

  #example .dropdown-toggle {
    display: none;
  }

}

@import "rsuite/dist/rsuite.css";

@media only screen and (max-width: 480px) {
  .en-nn.stv-radio-button+label {
    text-align: center;
    font-size: 12px !important;
    font-weight: 500;
  }

  .participants .stv-radio-button+label {
    padding: 0.6rem 1rem !important;
  }
}


@media only screen and (max-width: 376px) {
  .en-nn.stv-radio-button+label {

    text-align: center;
    padding: 5px 8px;
  }

  .participants .stv-radio-button+label {
    padding: 0.6rem 0.5rem !important;
  }

  #example .dropdown-menu li {
    width: 48%;
    margin: 2px;
  }

  #example .dropdown-menu {
    display: flex;
    flex-wrap: wrap;
  }
}